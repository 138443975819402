/* eslint-disable no-useless-escape */
// ** React Imports
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import MuiStep from '@mui/material/Step'
import StepperCustomDot from 'src/views/samples/create-sample/StepperCustomDot'
import Typography, { TypographyProps } from '@mui/material/Typography'

import Link from '@mui/material/Link'
import * as XLSX from 'xlsx'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Third Party Imports
import { useDropzone } from 'react-dropzone'
import {
  Autocomplete,
  Breadcrumbs,
  Card,
  CardContent,
  CardContentProps,
  Checkbox,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  StepLabel,
  Stepper,
  StepProps,
  SwipeableDrawer,
  TextField,
  Tooltip
} from '@mui/material'
import Translations from '@/layouts/components/Translations'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import {
  assumeRole,
  createCatalog,
  createDirectoryIfValid,
  createProject,
  createSampleByMountPath,
  createUserCustomUploads,
  deleteUserCustomUploads,
  editUserCustomUploads,
  fetchProjects,
  getUserCustomUploads,
  getUserDirectUploads,
  getUserFiles,
  localUpload,
  Project,
  updateProject,
  UserCustomUploads,
  UserDirectUploads
} from '@/@core/services/app.service'
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid'
import { NObject } from '@/@core/components/filter-variants'
import { formatDate, isWindow, unique } from '@/@core/utils/utils'
import React from 'react'
import { FileDocument, FolderOpen, Home } from 'mdi-material-ui'
import PositionedSnackbar from '@/@core/components/message'
import ProjectDialog from '@/views/samples/DialogProject'
import StepperWrapper from '@/@core/styles/mui/stepper'
import { message } from 'antd'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiPaper-root': {
    maxWidth: 1400,
    minHeight: 500
  }
}))

const Step = styled(MuiStep)<StepProps>(({ theme }) => ({
  '&:not(:last-of-type)': {
    marginBottom: theme.spacing(4)
  },
  '& .MuiStepLabel-root': {
    padding: 0,
    cursor: 'pointer'
  }
}))

const StepperHeaderContainer = styled(CardContent)<CardContentProps>(
  ({ theme }) => ({
    minWidth: 200,
    borderRight: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('md')]: {
      borderRight: 0,
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  })
)

interface FileProp {
  name: string
  type: string
  size: number
}

interface FilmOptionType {
  inputValue?: string
  id?: number
  name: string
}
interface Props {
  fn?: (file: File[]) => void
  uploadFn?: (file: File[]) => void
  accept?: string
  disableList?: boolean
  sx?: any
}
const filter = createFilterOptions<FilmOptionType>()

// Styled component for the upload image inside the dropzone area
const Img = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(15.75)
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4)
  },
  [theme.breakpoints.down('sm')]: {
    width: 160
  }
}))

const TypographyEllipsis = styled(Typography)(() => ({
  width: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: '0.875rem'
}))

// Styled component for the heading inside the dropzone area
const HeadingTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4)
  }
}))

const LocalFileUploaderMultiple = React.forwardRef((props: Props, ref) => {
  const { t } = useTranslation()
  const [messageApi, contextHolder] = message.useMessage()

  // ** State
  const tableRef = React.useRef<any>()
  const inputRef = React.useRef<any>()
  const messageRef = React.useRef<any>()
  const containerRef = React.useRef<any>()
  const [files, setFiles] = useState<any[]>([])
  const [file, setFile] = useState<File>()
  const [directorys, setDirectorys] = useState<any[]>([])
  const [localFileListOpen, setLocalFileListOpen] = useState(false)
  const [Open, setOpen] = useState(false)
  const [directory, setDirectory] = useState('')
  const [createDirOpen, setCreateDirOpen] = useState(false)
  const [localFileList, setLocalFileList] = useState<any[]>([])
  const [xlsxData, setXlsxData] = useState<any[]>([])
  const [value, setValue] = React.useState<FilmOptionType | null>(null)
  const [project, setProject] = React.useState<any>(null)
  const [isAna, setIsAna] = React.useState<boolean>(false)
  const [isCnv, setIsCnv] = React.useState<boolean>(false)
  const [openProject, setOpenProject] = React.useState<boolean>(false)

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5
  })
  const [activeStep, setActiveStep] = useState<number>(0)
  const steps = [
    {
      title: t('上传信息'),
      icon: 'mdi:tag-outline',
      subtitle: t('选择上传信息')
    },
    {
      title: t('文件上传'),
      subtitle: t('患者信息文件上传'),
      icon: 'mdi:clipboard-text-outline'
    }
  ]

  const success = () => {
    messageApi.open({
      type: 'success',
      content: '新建成功'
    })
  }

  const error = () => {
    messageApi.open({
      type: 'error',
      content: '新建错误，请联系管理员'
    })
  }

  const info = () => {
    messageApi.open({
      type: 'loading',
      content: '新建中，请稍后'
    })
  }

  // ** Hooks
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles: File[]) => {
      //
      console.log(acceptedFiles)

      if (acceptedFiles.length) {
        setFile(acceptedFiles[0])

        const file = acceptedFiles[0]

        if (file) {
          console.log(acceptedFiles)
          const fileName = file.name.replace(/\s+/g, '')

          const path = directorys.join('/')

          messageRef?.current?.handleClick(t('上传中，请稍后'), 'info')

          const reader = new FileReader()

          reader.onload = async (e) => {
            info()
            const data = e.target?.result
            const workbook = XLSX.read(data, { type: 'buffer' })
            const sheetNames = workbook.SheetNames

            let combinedData: any[] = []

            sheetNames.forEach((sheetName) => {
              const worksheet = workbook.Sheets[sheetName]

              const jsonData = XLSX.utils.sheet_to_json(worksheet)

              combinedData = combinedData.concat(jsonData).map((item) => {
                if (typeof item === 'object') {
                  const obj: NObject = {}

                  Object.keys(item).map((key: string) => {
                    const value = item[key]

                    obj[key.trim()] = value
                  })

                  return obj
                }

                return item
              })
            })

            const datas = combinedData.map((item, index) => {
              return {
                ...item,
                id: index
              }
            })

            setXlsxData(datas)

            const createSampleData = await Promise.all(
              files
                .filter((i) => i.type === 'directory')
                .map(async (i) => {
                  const fd = new FormData()

                  fd.append('file', file, encodeURI(fileName))

                  fd.append('projectId', project)

                  fd.append('status', `${Boolean(isAna)}`)

                  fd.append('isCnv', `${Boolean(isCnv)}`)

                  fd.append('parent', `${path}/${i.id}`)

                  console.log(`${Boolean(isAna)}`, `${path}/${i.id}`, project)

                  return await putSampleByMountPath.mutateAsync({
                    fd,
                    data: datas
                  })
                })
            )

            if (
              createSampleData &&
              createSampleData.length &&
              createSampleData.some((i) => i && i.success)
            ) {
              const allData = createSampleData
                .filter((i) => i && i.success)
                .map((i) => i.sampleList)
                .flat()

              messageRef?.current?.handleClick(t('新建成功'), 'success')

              const sampleList = allData as any[]

              console.log(allData)

              const newData = datas.map((item: any) => {
                const temp = sampleList.find(
                  (i) => i.name === item['实验室编号(样本编号)']
                )

                if (temp)
                  return {
                    ...item,
                    createStatus: true
                  }
                else
                  return {
                    ...item,
                    createStatus: false
                  }
              })

              success()

              setXlsxData(newData)
            }

            console.log(combinedData, workbook, sheetNames)
          }

          await reader.readAsArrayBuffer(file)
        }
      }
    },
    disabled: !files.some((item) => item.type === 'directory')
  })

  const { getRootProps: getFileRootProps, getInputProps: getFileInputProps } =
    useDropzone({
      onDrop: async (acceptedFiles: File[]) => {
        //
        console.log(acceptedFiles)

        if (acceptedFiles.length) {
          setFile(acceptedFiles[0])

          const file = acceptedFiles[0]

          if (file) {
            const fd = new FormData()
            const fileName = file.name.replace(/\s+/g, '')

            fd.append('file', file, encodeURI(fileName))

            const path = `/user_files/${directorys.join('/')}`

            messageApi.open({
              type: 'loading',
              content: '上传中，请稍后'
            })

            console.log(path, directorys)

            fd.append('directory', path)

            LocalUpload.mutateAsync(fd)
          }
        }
      }
    })

  const renderFooter = () => {
    const stepCondition = activeStep === steps.length - 1

    return (
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={handlePrev}
          disabled={activeStep === 0}
          startIcon={<Icon icon="mdi:arrow-left" />}
        >
          {t('Previous')}
        </Button>

        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} accept={'.xlsx'} />

          {stepCondition ? (
            ''
          ) : (
            <Button
              variant="contained"
              color={'primary'}
              {...(!stepCondition
                ? { endIcon: <Icon icon="mdi:arrow-right" /> }
                : {})}
              onClick={() => {
                if (project) {
                  handleNext()
                } else {
                  messageApi.open({
                    type: 'warning',
                    content: '请选择项目'
                  })
                }
              }}
            >
              {t('Next')}
            </Button>
          )}
        </div>
      </Box>
    )
  }

  // Handle Stepper
  const handleNext = () => {
    setActiveStep(activeStep + 1)
  }

  const handlePrev = () => {
    if (activeStep !== 0) {
      setActiveStep(activeStep - 1)
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('File Name'),
      flex: 1,
      renderCell: (row) => {
        return (
          <Stack
            direction={'row'}
            justifyContent="space-between"
            alignItems={'center'}
          >
            {row.row.type === 'directory' ? (
              <IconButton
                sx={{
                  color: 'rgb(254,203,7)'
                }}
              >
                <FolderOpen />
              </IconButton>
            ) : (
              <IconButton>
                <FileDocument />
              </IconButton>
            )}

            <Typography
              sx={{
                color: '#0064c8',
                ':hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }
              }}
              onClick={async () => {
                if (row.row.type === 'directory') {
                  await fetchUserLocalFiles.mutateAsync(
                    `${directorys.join('/')}/${row.row.name}/`
                  )

                  setDirectorys([...directorys, row.row.name])
                }
              }}
            >
              {row.row.name}
            </Typography>
          </Stack>
        )
      }
    },
    {
      field: 'size',
      headerName: t('Size'),
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      renderCell: (row) => {
        const size = row.row.size
        if (row.row.type === 'file') {
          if (size) {
            return (
              <TypographyEllipsis
                sx={{
                  textAlign: 'left'
                }}
              >
                {`${(size / 1024 / 1024).toFixed(2)}MB`}
              </TypographyEllipsis>
            )
          } else {
            return (
              <TypographyEllipsis
                sx={{
                  textAlign: 'left'
                }}
              >
                {'未统计'}
              </TypographyEllipsis>
            )
          }
        } else
          return (
            <TypographyEllipsis
              sx={{
                textAlign: 'left'
              }}
            >
              {''}
            </TypographyEllipsis>
          )
      }
    },
    {
      field: 'lastModified',
      headerName: t('submitAt'),
      width: 180,
      renderCell: (row) => {
        return ''
      }
    },
    {
      field: 'actions',
      headerName: t('Actions'),
      width: 120,
      renderCell: (row) => {
        return ''
      }
    }
  ]

  const columns2: GridColDef[] = [
    {
      field: '实验室编号(样本编号)',
      headerName: t('实验室编号(样本编号)'),
      sortable: false,
      width: 180
    },
    {
      field: '家系',
      headerName: t('家系'),
      sortable: false
    },
    {
      field: '昆华编号',
      headerName: t('昆华编号'),
      width: 150
    },
    {
      field: '受检者姓名',
      headerName: t('受检者姓名'),
      sortable: false
    },
    {
      field: '性别',
      headerName: t('性别'),
      sortable: false
    },
    {
      field: '年龄',
      headerName: t('年龄')
    },
    {
      field: '受检者类型',
      headerName: t('受检者类型'),
      sortable: false
    },
    {
      field: '临床症状',
      headerName: t('临床症状'),
      sortable: false
    },
    {
      field: '与先证者关系',
      headerName: t('与先证者关系'),
      flex: 1,
      sortable: false
    },
    {
      field: 'actions',
      headerName: t('status'),
      renderCell: (row) => {
        return !row.row.createStatus ? '失败' : '成功'
      },
      sortable: false
    }
  ]

  const handleSaveProject = (project: any) => {
    if ('name' in project && 'description' in project && 'id' in project) {
      updateProjectMutation.mutate(project)
    } else {
      createProjectMutation.mutate(project)
    }
  }

  const { data: projects = [], refetch } = useQuery<Project[]>(
    'project',
    async () => await fetchProjects({ pageNum: 1, pageSize: 1000 }),
    {
      keepPreviousData: true
    }
  )

  const createProjectMutation = useMutation(createProject, {
    onSuccess: () => {
      setOpenProject(false)

      messageApi.open({
        type: 'success',
        content: t('New successful')
      })

      refetch()
    },
    onError: (err: any) => {
      console.log(err)

      messageApi.open({
        type: 'error',
        content: err?.response?.data?.message ?? '请重试'
      })
    }
  })

  const updateProjectMutation = useMutation(updateProject, {
    onSuccess: () => {
      setOpenProject(false)

      messageApi.open({
        type: 'success',
        content: t('Edit success')
      })

      refetch()
    }
  })

  const getLocalList = async () => {
    await fetchUserLocalFiles.mutateAsync(
      directorys.length ? `/${directorys.join('/')}/` : ''
    )
  }

  const handleLocalClick = async () => {
    setLocalFileListOpen(true)

    await getLocalList()
  }

  const fetchUserLocalFiles = useMutation({
    mutationFn: async (path: string) =>
      await getUserFiles({
        parent: path
      }),
    onSuccess: (data) => {
      if (data) {
        setLocalFileList(
          data
            .map((i: any, index: number) => {
              return {
                id: i.name,
                ...i
              }
            })
            .sort((a: any, b: any) => {
              if (a.type === 'directory' && b.type === 'file') {
                return -1 // directory 排在 file 前面
              } else if (a.type === 'file' && b.type === 'directory') {
                return 1 // file 排在 directory 后面
              } else {
                return 0 // 如果 type 相同，则保持相对顺序（或可以根据需要进一步排序）
              }
            })
        )
      }
    }
  })

  const fetchUserLocalFiles2 = useMutation({
    mutationFn: async (path: string) =>
      await getUserFiles({
        parent: path
      })
  })

  const putSampleByMountPath = useMutation({
    mutationFn: async (params: any) => await createSampleByMountPath(params.fd),
    onSuccess: (data, params) => {
      if (data && data.success) {
        messageRef?.current?.handleClick(t('新建成功'), 'success')

        const sampleList = data.sampleList as any[]

        const newData = params.data.map((item: any) => {
          const temp = sampleList.find(
            (i) => i.name === item['实验室编号(样本编号)']
          )

          if (temp)
            return {
              ...item,
              createStatus: true
            }
          else
            return {
              ...item,
              createStatus: false
            }
        })

        success()

        setXlsxData(newData)

        console.log(params.data)
      }
    },
    onError: () => {
      error()
    }
  })

  const putDirectoryIfValid = useMutation({
    mutationFn: async (params: { parent: string }) =>
      await createDirectoryIfValid(params),
    onSuccess: (data) => {
      if (data && data.includes('successfully')) {
        getLocalList()

        setDirectory('')

        messageRef?.current?.handleClick(t('新建成功'), 'success')
      }
    }
  })

  const LocalUpload = useMutation({
    mutationFn: async (params: FormData) =>
      await localUpload({
        file: params,
        setProgress: '',
        getFileSize: ''
      }),

    onSuccess: (data) => {
      if (data) {
        messageApi.open({
          type: 'success',
          content: '上传成功'
        })

        getLocalList()
      }
    }
  })

  React.useEffect(() => {
    console.log(localFileList)
  }, [localFileList])

  React.useImperativeHandle(ref, () => {
    return {
      handleLocalClick
    }
  })

  return (
    <Fragment>
      {contextHolder}

      <BootstrapDialog
        onClose={() => {
          setLocalFileListOpen(false)

          setLocalFileList([])

          setFiles([])

          setDirectorys([])
        }}
        aria-labelledby="customized-dialog-title"
        open={localFileListOpen}
      >
        <DialogTitle>
          <Translations text={'文件列表'}></Translations>
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={() => {
            setLocalFileListOpen(false)

            setLocalFileList([])

            setFiles([])

            setDirectorys([])
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent dividers sx={{ width: 1400 }}>
          {!Open ? (
            <Stack height={'100%'} ref={containerRef}>
              <Stack
                my={2}
                justifyContent={'space-between'}
                direction="row"
                alignItems={'center'}
              >
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    underline="hover"
                    color="inherit"
                    onClick={async () => {
                      await fetchUserLocalFiles.mutateAsync('')

                      setDirectorys([])
                    }}
                  >
                    <IconButton>
                      <Home />
                    </IconButton>
                  </Link>

                  {directorys.map((item, index) => {
                    if (index === directorys.length - 1) {
                      return (
                        <Typography sx={{ color: 'text.primary' }}>
                          {item}
                        </Typography>
                      )
                    } else {
                      return (
                        <Link
                          underline="hover"
                          color="inherit"
                          onClick={async () => {
                            const index = directorys.findIndex(
                              (i) => i === item
                            )
                            const arr = directorys.slice(0, index + 1)
                            const path = directorys
                              .slice(0, index + 1)
                              .join('/')

                            console.log(path)

                            setDirectorys(arr)

                            await fetchUserLocalFiles.mutateAsync(`${path}/`)
                          }}
                        >
                          {item}
                        </Link>
                      )
                    }
                  })}
                </Breadcrumbs>

                <Stack direction={'row'} alignItems="center" spacing={2}>
                  <div {...getFileRootProps({ className: 'dropzonea' })}>
                    <input
                      {...getFileInputProps()}
                      accept={
                        '.vcf, .vcf.gz,.gvcf.gz, .gtc, .fq.gz, .fastq.gz,.naf,.bam,.fq'
                      }
                      ref={inputRef}
                    />

                    <Box>
                      <Tooltip
                        title={'请注意，文件上传至当前表格展示文件目录下'}
                      >
                        <Button
                          variant="contained"
                          onClick={() => {
                            inputRef.current &&
                              inputRef.current.click &&
                              inputRef.current.click()
                          }}
                          size="small"
                        >
                          {t('上传文件')}
                        </Button>
                      </Tooltip>
                    </Box>
                  </div>

                  <Box>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setCreateDirOpen(true)
                      }}
                    >
                      {t('新建目录')}
                    </Button>
                  </Box>

                  <Box></Box>

                  <Box textAlign={'right'}>
                    <Tooltip title={t('请选择文件目录，并上传患者信息')}>
                      {/* <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} accept={'.xlsx'} /> */}

                      <Button
                        disabled={
                          !files.some((item) => item.type === 'directory')
                        }
                        onClick={() => {
                          setOpen(true)

                          setXlsxData([])

                          setActiveStep(0)
                        }}
                        variant="contained"
                        size="small"
                      >
                        {t('模板上传')}
                      </Button>

                      {/* </div> */}
                    </Tooltip>
                  </Box>

                  <Box textAlign={'right'}>
                    <Button
                      onClick={() => {
                        getLocalList()
                      }}
                      variant="contained"
                      size="small"
                    >
                      {t('Refresh')}
                    </Button>
                  </Box>
                </Stack>
              </Stack>

              <DataGrid
                rows={localFileList}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10
                    }
                  }
                }}
                paginationModel={paginationModel}
                ref={tableRef}
                pageSizeOptions={[5, 10, 20]}
                disableRowSelectionOnClick
                checkboxSelection
                disableColumnMenu
                rowSelectionModel={files.map((i) => i.name)}
                onRowSelectionModelChange={(rowKey) => {
                  console.log(rowKey)

                  const data = rowKey
                    .map((item) => {
                      return localFileList.find((i) => i.id == item)
                    })
                    .filter((i) => i && i.name)

                  const others = files.filter(
                    (i) => !localFileList.some((j) => j.name === i.name)
                  )

                  console.log(others, files, data)

                  setFiles(
                    unique([...data, ...others], 'id').filter(
                      (i) => i && i.name
                    )
                  )
                }}
                onPaginationModelChange={setPaginationModel}
                sx={{
                  minHeight: 250,
                  maxHeight: 500
                }}
                key={localFileList.length}
              />
            </Stack>
          ) : (
            <Stack
              sx={{
                minHeight: 400
              }}
            >
              <StepperHeaderContainer>
                <StepperWrapper>
                  <Stepper
                    activeStep={activeStep}
                    orientation={isWindow() ? 'horizontal' : 'vertical'}
                    sx={{
                      '& .MuiStepConnector-line': isWindow()
                        ? {}
                        : {
                            border: 'none',
                            borderLeft: '3px solid',
                            borderLeftColor: 'primary.main'
                          }
                    }}
                  >
                    {steps.map((step, index) => {
                      return (
                        <Step key={index} onClick={() => setActiveStep(index)}>
                          <StepLabel StepIconComponent={StepperCustomDot}>
                            <div className="step-label">
                              <Typography className="step-number">{`0${
                                index + 1
                              }`}</Typography>

                              <div>
                                <Typography className="step-title">
                                  {step.title}
                                </Typography>

                                <Typography className="step-subtitle">
                                  {step.subtitle}
                                </Typography>
                              </div>
                            </div>
                          </StepLabel>
                        </Step>
                      )
                    })}
                  </Stepper>
                </StepperWrapper>
              </StepperHeaderContainer>

              <Divider sx={{ m: '0 !important' }} />

              <Stack flex={1} justifyContent="space-between">
                {activeStep === 0 ? (
                  <Box mt={20}>
                    <Box
                      className={'flex items-center gap-2'}
                      alignItems="center"
                    >
                      <Typography
                        className="font-medium"
                        color="text.primary"
                        sx={{
                          wordBreak: 'keep-all',
                          mr: 8
                        }}
                        flex={2}
                        textAlign="right"
                      >
                        {t('project')}
                        <span
                          style={{
                            color: 'red'
                          }}
                        >
                          *
                        </span>
                        :
                      </Typography>

                      <FormControl fullWidth sx={{ flex: isWindow() ? 7 : 5 }}>
                        <Box width={'50%'}>
                          <Autocomplete
                            value={value}
                            onChange={async (event, newValue) => {
                              console.log(newValue)

                              if (
                                typeof newValue !== 'string' &&
                                newValue &&
                                newValue.id
                              ) {
                                setValue(newValue)

                                setProject(newValue.id)
                              } else {
                                if (typeof newValue === 'string') {
                                  setValue({
                                    name: newValue
                                  })

                                  const params = {
                                    name: newValue,
                                    isPublic: 0 as 0 | 1,
                                    createdAt: new Date(),
                                    description: ''
                                  }
                                  const project =
                                    await createProjectMutation.mutateAsync(
                                      params
                                    )

                                  const temp = project.find(
                                    (item: any) => item.name === params.name
                                  )

                                  console.log(project)

                                  setProject(temp.id)
                                } else if (newValue && newValue.inputValue) {
                                  // Create a new value from the user input
                                  setValue({
                                    name: newValue.inputValue
                                  })
                                  const params = {
                                    name: newValue.inputValue,
                                    isPublic: 0 as 0 | 1,
                                    createdAt: new Date(),
                                    description: ''
                                  }
                                  const project =
                                    await createProjectMutation.mutateAsync(
                                      params
                                    )

                                  const temp = project.find(
                                    (item: any) => item.name === params.name
                                  )

                                  console.log(project)

                                  setProject(temp.id)
                                } else {
                                  setValue(newValue)
                                }
                              }
                            }}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params)

                              const { inputValue } = params

                              // Suggest the creation of a new value
                              const isExisting = options.some(
                                (option) => inputValue === option.name
                              )

                              if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                  inputValue,
                                  name: `添加 "${inputValue}"`
                                })
                              }

                              return filtered
                            }}
                            size="small"
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="free-solo-with-text-demo"
                            options={
                              (projects &&
                                projects.map((item) => {
                                  return {
                                    id: item.id,
                                    name: item.name,
                                    inputValue: item.name
                                  }
                                })) ??
                              []
                            }
                            getOptionLabel={(option) => {
                              // Value selected with enter, right from the input
                              if (typeof option === 'string') {
                                return option
                              }

                              // Add "xxx" option created dynamically
                              if (option.name) {
                                return option.name
                              }

                              // Regular option
                              return option.name
                            }}
                            renderOption={(props, option) => {
                              return <li {...props}>{option.name}</li>
                            }}
                            freeSolo
                            renderInput={(params) => (
                              <TextField required {...params} />
                            )}
                          />
                        </Box>
                      </FormControl>
                    </Box>

                    <Box
                      className={'flex items-center gap-2'}
                      alignItems="center"
                    >
                      <Typography
                        className="font-medium"
                        color="text.primary"
                        sx={{
                          wordBreak: 'keep-all',
                          mr: 8
                        }}
                        flex={2}
                        textAlign="right"
                      >
                        {t('是否立即分析')}:
                      </Typography>

                      <FormControl fullWidth sx={{ flex: isWindow() ? 7 : 5 }}>
                        <Box width={'50%'}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={
                                  !files.some(
                                    (item) => item.type === 'directory'
                                  )
                                }
                                checked={isAna}
                                onChange={(e) => {
                                  setIsAna(e.target.checked)
                                }}
                              />
                            }
                            label=""
                          />
                        </Box>
                      </FormControl>
                    </Box>

                    <Box
                      className={'flex items-center gap-2'}
                      alignItems="center"
                    >
                      <Typography
                        className="font-medium"
                        color="text.primary"
                        sx={{
                          wordBreak: 'keep-all',
                          mr: 8
                        }}
                        flex={2}
                        textAlign="right"
                      >
                        {t('Run Exome CNV')}:
                      </Typography>

                      <FormControl fullWidth sx={{ flex: isWindow() ? 7 : 5 }}>
                        <Box width={'50%'}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={
                                  !files.some(
                                    (item) => item.type === 'directory'
                                  )
                                }
                                checked={isCnv}
                                onChange={(e) => {
                                  setIsCnv(e.target.checked)
                                }}
                              />
                            }
                            label=""
                          />
                        </Box>
                      </FormControl>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    className={'flex items-center gap-2'}
                    alignItems="center"
                  >
                    <Typography
                      className="font-medium"
                      color="text.primary"
                      sx={{
                        wordBreak: 'keep-all',
                        mr: 8
                      }}
                      flex={1}
                      textAlign="right"
                    >
                      {t('上传信息确认')}:
                    </Typography>

                    <FormControl fullWidth sx={{ flex: isWindow() ? 7 : 5 }}>
                      <Box>
                        <DataGrid
                          rows={xlsxData}
                          disableColumnMenu
                          columns={columns2}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 5
                              }
                            }
                          }}
                          pageSizeOptions={[5, 10, 20]}
                          disableRowSelectionOnClick
                        />
                      </Box>
                    </FormControl>
                  </Box>
                )}

                {renderFooter()}
              </Stack>
            </Stack>
          )}
        </DialogContent>

        <DialogActions>
          <Stack
            width={'100%'}
            direction={'row'}
            spacing={4}
            margin={'10px 0'}
            alignItems="center"
            justifyContent={'center'}
          >
            <Button
              autoFocus
              variant="outlined"
              onClick={() => {
                if (Open) {
                  setOpen(false)
                } else {
                  setLocalFileListOpen(false)

                  setLocalFileList([])

                  setFiles([])
                }
              }}
            >
              {t('cancel')}
            </Button>

            {!Open ? (
              <Button
                autoFocus
                variant="contained"
                onClick={async () => {
                  if (Open) {
                    setOpen(false)
                  } else {
                    const fileList = files
                      .filter((i) => i.type === 'file')
                      .map((i) => {
                        return {
                          size: 1,
                          ...i
                        }
                      })

                    const traverseDirectory = async (
                      path: string,
                      baseDir = ''
                    ) => {
                      const data = await fetchUserLocalFiles2.mutateAsync(path)

                      if (data && data.length) {
                        await Promise.all(
                          data.map(async (i: any) => {
                            const newPath = baseDir
                              ? `${baseDir}/${i.name}`
                              : i.name
                            const fullPath = `${path}${i.name}/`

                            if (i.type === 'directory') {
                              await traverseDirectory(fullPath, newPath)
                            } else {
                              if (
                                i.name.endsWith('.vcf') ||
                                i.name.endsWith('.vcf.gz') ||
                                i.name.endsWith('.gvcf.gz') ||
                                i.name.endsWith('.gtc') ||
                                i.name.endsWith('.bam') ||
                                i.name.endsWith('.fq.gz') ||
                                i.name.endsWith('.fq') ||
                                i.name.endsWith('.fastq.gz') ||
                                i.name.endsWith('.naf')
                              ) {
                                fileList.push({
                                  size: 1,
                                  ...i
                                })
                              }
                            }
                          })
                        )
                      }
                    }

                    await Promise.all(
                      files
                        .filter((i) => i.type === 'directory')
                        .map(async (i) => {
                          console.log(`${directorys.join('/')}/${i.name}/`)

                          const path = `${directorys.join('/')}/${i.name}/`

                          await traverseDirectory(path, '')
                        })
                    )

                    console.log(fileList)

                    props.fn && props.fn(unique(fileList, 'name'))

                    setLocalFileListOpen(false)

                    setLocalFileList([])

                    setFiles([])
                  }
                }}
              >
                {t('create')}
              </Button>
            ) : (
              ''
            )}
          </Stack>
        </DialogActions>
      </BootstrapDialog>

      <SwipeableDrawer
        container={containerRef.current}
        anchor="right"
        open={createDirOpen}
        onClose={() => {
          setCreateDirOpen(false)
        }}
        onOpen={() => {
          setCreateDirOpen(true)
        }}
      >
        <Stack width={'30vw'}>
          <Box>
            <DialogTitle>
              <Translations text={'新建目录'}></Translations>
            </DialogTitle>

            <IconButton
              aria-label="close"
              onClick={() => {
                setCreateDirOpen(false)
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box>
            <Grid container px={4} pr={18}>
              <Grid
                item
                xs={3}
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  justifyContent: 'start'
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    pt: 2
                  }}
                >
                  {t('目录名')}
                </Typography>
              </Grid>

              <Grid item xs={9}>
                <Stack spacing={2}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    fullWidth
                    placeholder="请输入目录"
                    value={directory}
                    onChange={(e) => {
                      setDirectory(e.target.value)
                    }}
                  />

                  <Typography
                    sx={{
                      fontWeight: 400,
                      color: '#999',
                      fontSize: '15px'
                    }}
                  >
                    {t('目录命名规范：')}
                  </Typography>

                  <Stack pl={6}>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: '#999',
                        fontSize: '15px'
                      }}
                    >
                      {t('1、不允许使用表情符，请使用符合要求的 UTF-8 字符；')}
                    </Typography>

                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: '#999',
                        fontSize: '15px'
                      }}
                    >
                      {t(
                        '2、/ 用于分割路径，可快速创建子目录，但不要以 / 或  开头，不要出现连续的 /；'
                      )}
                    </Typography>

                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: '#999',
                        fontSize: '15px'
                      }}
                    >
                      {t('3、不允许出现名为 .. 的子目录；')}
                    </Typography>

                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: '#999',
                        fontSize: '15px'
                      }}
                    >
                      {t('4、总长度控制在 1~254 个字符。')}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>

          <Box position={'absolute'} bottom={0} left={5} width={'98%'}>
            <Divider />

            <Stack
              width={'100%'}
              direction={'row'}
              spacing={4}
              margin={'10px 0'}
              alignItems="center"
              justifyContent={'start'}
              pl={15}
            >
              <Button
                autoFocus
                variant="contained"
                onClick={() => {
                  setCreateDirOpen(false)

                  putDirectoryIfValid.mutateAsync({
                    parent: directory
                  })
                }}
              >
                {t('create')}
              </Button>

              <Button
                autoFocus
                variant="outlined"
                onClick={() => {
                  setCreateDirOpen(false)
                }}
              >
                {t('cancel')}
              </Button>
            </Stack>
          </Box>
        </Stack>
      </SwipeableDrawer>

      <ProjectDialog
        projectToEdit={null}
        open={openProject}
        onClose={() => {
          setOpenProject(false)
        }}
        onSave={handleSaveProject}
      />

      <PositionedSnackbar ref={messageRef} />
    </Fragment>
  )
})

export default LocalFileUploaderMultiple
