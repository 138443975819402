import * as React from 'react'
import { Box, Button, Grid, Stack } from '@mui/material'
import { DropzoneWrapper } from './DropzoneStyle'
import FileUploaderMultiple from '@/views/forms/file-uploader/FileUploaderMultiple'
import { useMutation } from 'react-query'
import CreateSampleWizard from '.'
import {
  createSample,
  createUserCustomUploadsByList,
  createUserDirectUploadsByList,
  getTermByClinicalInfo,
  GetTermsByClinicalInfoDto,
  Patients,
  Sample,
  saveAnalysis,
  SentieonPipelines,
  ServerName,
  TaskStatus,
  TaskWesCnvBatchDto,
  translate,
  TranslateBulkDto,
  TranslateDto,
  translates,
  upload,
  UserCustomUploads,
  UserDirectUploads
} from '@/@core/services/app.service'
import { getCode } from '@/@core/layouts/utils'
import PositionedSnackbar from '@/@core/components/message'
import { useTranslation } from 'react-i18next'
import { calculateBirthDate, debounce, unique } from '@/@core/utils/utils'
import { NObject } from '@/@core/components/filter-variants'
import _ from 'lodash'
import { Carousel } from 'antd'
import OssFileUploaderMultiple from '@/views/forms/file-uploader/OssFileUploaderMultiple'
import LocalFileUploaderMultiple from '@/views/forms/file-uploader/LocalFileUploaderMultiple'
import FamilyFileUploaderMultiple from '@/views/forms/file-uploader/FamilyFileUploaderMultiple'
import CarrierFileUploaderMultiple from '@/views/forms/file-uploader/CarrierFileUploaderMultiple'

interface Analysis {
  name: string
  sampleList: [] | any
  description: string
  project_id: number | null
  analysisType: string
  status?: string
}

interface Props {
  fn: any
  setHasTermsOpen?: any
  setLoading?: any
}

interface Samples {
  progress: number
  cnvProgress: number
  file: File
  prefix: string
  cnvPrefix?: string
  fileSize: number
  cnvFileSize?: number
  sampleValue: any
  isCnv?: boolean
  isCreate?: boolean
}

const contentStyle: React.CSSProperties = {
  margin: 0,
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79'
}

const CreateSample = React.forwardRef((props: Props, ref) => {
  const [samples, setSamples] = React.useState<Samples[]>([])
  const [ossFiles, setOssFiles] = React.useState<any[]>([])
  const createRef = React.useRef<any>()
  const messageRef = React.useRef<any>()
  const uploadFileRef = React.useRef<any>()
  const ossRef = React.useRef<any>()
  const localRef = React.useRef<any>()
  const famRef = React.useRef<any>()
  const carrierRef = React.useRef<any>()
  const [count, setCount] = React.useState(0)
  const [isOss, setIsOss] = React.useState(false)
  const [isLocal, setIsLocal] = React.useState(false)
  const [isFam, setIsFam] = React.useState(false)
  const [isCarrier, setIsCarrier] = React.useState(false)
  const [confirm, setConfirm] = React.useState(false)
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const { t } = useTranslation()
  const [Key, setKey] = React.useState(0)
  const defaultValues = {
    sample: {
      sampleName: '',
      sampleSource: 'blood',
      type: 'WES',
      genomeBuild: 'hg19' as 'hg19' | 'hg38',
      projectId: null,
      genePanel: null,
      targetRegionId: null,
      prefix: '',
      fileSize: 0,
      hasCnv: false,
      cnvFile: '',
      cnvName: '',
      isFq: false,
      fqFiles: []
    },
    clinical: {
      termList: [],
      clinicalInformation: ''
    },
    patient: {
      patientName: '',
      infirmaryName: '',
      departmentName: '',
      caseNumber: '',
      birthday: null,
      doctor: '',
      gender: 'unknown' as 'male' | 'female' | 'unknown',
      race: null,
      acceptDate: null,
      tooptip: [],
      status: true,
      pcrFree: false,
      sentieonPipeline: SentieonPipelines.DNASCOPE,
      removeDuplication: true,
      hasSv: false,
      svFile: '',
      svName: '',
      hasCnv: false,
      cnvFile: '',
      cnvName: '',
      isFq: false,
      fqFiles: []
    }
  }

  const handleUpload = async (
    files: File[],
    isCarrier: boolean,
    isFam: boolean
  ) => {
    console.log('isFam', isFam, isCarrier)

    const fileData = files.map((items) => {
      console.log(items)

      const temp =
        items.name.includes('R1') || items.name.includes('R2') ? 'R' : '_'
      const nameArr = items.name.split('.')
      const suffix = nameArr[nameArr.length - 1]
      const tempR1 = items.name.split(`${temp}1.`)
      const tempR2 = items.name.split(`${temp}2.`)
      const fileType =
        items.name.includes('.vcf') ||
        items.name.includes('.vcf.gz') ||
        items.name.includes('.gvcf.gz')
          ? 'vcf'
          : items.name.includes('.gtc')
          ? 'gtc'
          : items.name.includes('.bam')
          ? 'bam'
          : items.name.includes('.fq.gz') || items.name.includes('.fastq.gz')
          ? 'fastq'
          : items.name.includes('.naf')
          ? 'naf'
          : 'vcf'

      if (tempR1.length === 2 && (fileType === 'fastq' || fileType === 'naf')) {
        // R1
        if (items.name.includes('_L') && items.name.split('_').length >= 4) {
          const first = items.name.includes('_L')
            ? `${items.name.split('_L')[0]}${items.name.split('_')[2]}`
            : tempR1[0]

          return {
            file: items,
            group: `${first}`
          }
        }

        const first = items.name.includes('_L')
          ? items.name.split('_L')[0]
          : tempR1[0]

        return {
          file: items,
          group: `${first}`
        }
      } else if (
        tempR2.length === 2 &&
        (fileType === 'fastq' || fileType === 'naf')
      ) {
        if (items.name.includes('_L') && items.name.split('_').length >= 4) {
          const first = items.name.includes('_L')
            ? `${items.name.split('_L')[0]}${items.name.split('_')[2]}`
            : tempR1[0]

          return {
            file: items,
            group: `${first}`
          }
        }
        const first = items.name.includes('_L')
          ? items.name.split('_L')[0]
          : tempR2[0]

        // R2
        return {
          file: items,
          group: `${first}`
        }
      } else {
        // vcf...
        return {
          file: items,
          group: `${items.name}`
        }
      }
    })

    console.log(files, fileData)

    const groupedData: NObject = fileData.reduce((groups: any, item) => {
      const group = item.group

      if (!groups[group]) {
        groups[group] = []
      }

      groups[group].push(item)

      return groups
    }, {})

    const arr = Object.getOwnPropertyNames(groupedData).map((key) => {
      const item = groupedData[key] as {
        gropu: string
        file: File
      }[]

      if (item && item.length === 1) {
        const file = item[0].file

        if (isFam || isCarrier) {
          const arr = `${file.name}`.split('_')

          const type = arr.shift()
          const newFileName = arr.join('_')

          const newFile = new File([file], newFileName, {
            type: file.type,

            lastModified: file.lastModified
          })

          const user = JSON.parse(localStorage.getItem('user') || '{}')
          const geneSetsId = user.geneSetsId ?? null

          const fileName = file.name.replace(/\s+/g, '')
          let baseName = fileName.substring(0, fileName.lastIndexOf('.'))

          if (fileName.includes('.cnv')) {
            baseName = fileName.substring(0, fileName.lastIndexOf('.cnv'))
          }

          return {
            file: newFile,
            type: type,
            progress: 0,
            cnvProgress: 0,
            prefix: '',
            cnvPrefix: '',
            fileSize: 0,
            cnvFileSize: 0,
            sampleValue: {
              ...defaultValues,
              sample: {
                ...defaultValues.sample,
                targetRegionId: geneSetsId,
                sampleName: baseName
              }
            }
          }
        } else {
          const fileName = file.name.replace(/\s+/g, '')
          let baseName = fileName.substring(0, fileName.lastIndexOf('.'))

          if (fileName.includes('.cnv')) {
            baseName = fileName.substring(0, fileName.lastIndexOf('.cnv'))
          }

          const user = JSON.parse(localStorage.getItem('user') || '{}')
          const geneSetsId = user.geneSetsId ?? null

          return {
            file: file,
            progress: 0,
            cnvProgress: 0,
            prefix: '',
            cnvPrefix: '',
            fileSize: 0,
            cnvFileSize: 0,
            sampleValue: {
              ...defaultValues,
              sample: {
                ...defaultValues.sample,
                targetRegionId: geneSetsId,
                sampleName: baseName
              }
            }
          }
        }
      } else {
        if (isFam || isCarrier) {
          const [first, ...other] = item
          const file = first.file
          const otherFile = other.map((item) => {
            const arr = `${item.file.name}`.split('_')

            const type = arr.shift()

            const newFileName = arr.join('_')

            const newFile = new File([item.file], newFileName, {
              type: item.file.type,

              lastModified: item.file.lastModified
            })

            return {
              fileName: item.file.name,
              file: newFile,
              process: 0
            }
          })
          const arr = `${file.name}`.split('_')

          const type = arr.shift()
          const newFileName = arr.join('_')

          const newFile = new File([file], newFileName, {
            type: file.type,

            lastModified: file.lastModified
          })
          const user = JSON.parse(localStorage.getItem('user') || '{}')

          const geneSetsId = user.geneSetsId ?? null

          const fileName = file.name.replace(/\s+/g, '')
          let baseName = fileName.substring(0, fileName.lastIndexOf('.'))

          if (fileName.includes('.cnv')) {
            baseName = fileName.substring(0, fileName.lastIndexOf('.cnv'))
          }

          return {
            file: newFile,
            type: type,
            progress: 0,
            cnvProgress: 0,
            prefix: '',
            cnvPrefix: '',
            fileSize: 0,
            cnvFileSize: 0,
            sampleValue: {
              ...defaultValues,
              sample: {
                ...defaultValues.sample,
                targetRegionId: geneSetsId,
                sampleName: baseName
              },
              patient: {
                ...defaultValues.patient,
                fqFiles: otherFile
              }
            }
          }
        } else {
          const [first, ...other] = item
          const file = first.file
          const otherFile = other.map((item) => {
            return {
              fileName: item.file.name,
              file: item.file,
              process: 0
            }
          })

          console.log(first, other)

          const fd = new FormData()
          const fileName = file.name.replace(/\s+/g, '')
          let baseName = fileName.substring(0, fileName.lastIndexOf('.'))

          if (fileName.includes('.cnv')) {
            baseName = fileName.substring(0, fileName.lastIndexOf('.cnv'))
          }

          fd.append('file', file, encodeURI(fileName))

          const user = JSON.parse(localStorage.getItem('user') || '{}')
          const geneSetsId = user.geneSetsId ?? null

          return {
            file: file,
            progress: 0,
            cnvProgress: 0,
            prefix: '',
            cnvPrefix: '',
            fileSize: 0,
            cnvFileSize: 0,
            sampleValue: {
              ...defaultValues,
              sample: {
                ...defaultValues.sample,
                targetRegionId: geneSetsId,
                sampleName: baseName
              },
              patient: {
                ...defaultValues.patient,
                fqFiles: otherFile
              }
            }
          }
        }
      }
    })

    console.log('arr', arr)

    setSamples(arr)

    setTimeout(() => {
      createRef.current &&
        createRef.current.handleUpload(
          arr.map((item) => item.file),
          arr
        )

      console.log('createRef.current', createRef.current)
    }, 100)
  }

  const handleOssFiles = (
    files: { name: string; url: string; size: number; isCreated: boolean }[]
  ) => {
    setOssFiles(files)
    const fileData = files.map((items) => {
      console.log(items)

      const temp =
        items.name.includes('R1') || items.name.includes('R2') ? 'R' : '_'
      const nameArr = items.name.split('.')
      const suffix = nameArr[nameArr.length - 1]
      const tempR1 = items.name.split(`${temp}1.`)
      const tempR2 = items.name.split(`${temp}2.`)
      const fileType =
        items.name.includes('.vcf') ||
        items.name.includes('.vcf.gz') ||
        items.name.includes('.gvcf.gz')
          ? 'vcf'
          : items.name.includes('.gtc')
          ? 'gtc'
          : items.name.includes('.bam')
          ? 'bam'
          : items.name.includes('.fq.gz') || items.name.includes('.fastq.gz')
          ? 'fastq'
          : items.name.includes('.naf')
          ? 'naf'
          : 'vcf'

      console.log('123', tempR1, tempR2, fileType, items.name)

      if (tempR1.length === 2 && (fileType === 'fastq' || fileType === 'naf')) {
        // R1
        const str1 = items.name.split('_')[0]
        const str3 =
          items.name.split('_').length > 3 ? items.name.split('_')[2] : null
        const first = items.name.includes('_L') ? `${str1}_${str3}` : tempR1[0]

        return {
          file: items,
          group: `${first}`
        }
      } else if (
        tempR2.length === 2 &&
        (fileType === 'fastq' || fileType === 'naf')
      ) {
        const str1 = items.name.split('_')[0]
        const str3 =
          items.name.split('_').length > 3 ? items.name.split('_')[2] : null
        const first = items.name.includes('_L') ? `${str1}_${str3}` : tempR2[0]

        // R2
        return {
          file: items,
          group: `${first}`
        }
      } else {
        // vcf...
        return {
          file: items,
          group: `${items.name.split('_')[0]}${suffix}`
        }
      }
    })
    const groupedData: NObject = fileData.reduce((groups: any, item) => {
      const group = item.group

      if (!groups[group]) {
        groups[group] = []
      }

      groups[group].push(item)

      return groups
    }, {})

    console.log('123', fileData)

    const arr = Object.getOwnPropertyNames(groupedData).map((key) => {
      const item = groupedData[key] as {
        gropu: string
        file: { name: string; url: string; size: number }
      }[]

      if (item && item.length === 1) {
        const file = item[0].file

        const fileName = file.name.replace(/\s+/g, '')
        let baseName = fileName.substring(0, fileName.lastIndexOf('.'))

        if (fileName.includes('.cnv')) {
          baseName = fileName.substring(0, fileName.lastIndexOf('.cnv'))
        }

        const user = JSON.parse(localStorage.getItem('user') || '{}')
        const geneSetsId = user.geneSetsId ?? null

        return {
          file: file as any,
          progress: 100,
          cnvProgress: 0,
          prefix: file.url,
          cnvPrefix: '',
          fileSize: file.size / 1024 / 1024,
          cnvFileSize: 0,
          sampleValue: {
            ...defaultValues,
            sample: {
              ...defaultValues.sample,
              targetRegionId: geneSetsId,
              sampleName: baseName
            }
          }
        }
      } else {
        const [first, ...other] = item
        const file = first.file
        const otherFile = other.map((item) => {
          return {
            fileName: item.file.name,
            file: item.file,
            process: 100,
            fileSize: item.file.size
          }
        })

        console.log(first, other)

        const fileName = file.name.replace(/\s+/g, '')
        let baseName = fileName.substring(0, fileName.lastIndexOf('.'))

        if (fileName.includes('.cnv')) {
          baseName = fileName.substring(0, fileName.lastIndexOf('.cnv'))
        }

        const user = JSON.parse(localStorage.getItem('user') || '{}')
        const geneSetsId = user.geneSetsId ?? null

        return {
          file: file as any,
          progress: 100,
          cnvProgress: 0,
          prefix: file.url,
          cnvPrefix: '',
          fileSize: file.size / 1024 / 1024,
          cnvFileSize: 0,
          sampleValue: {
            ...defaultValues,
            sample: {
              ...defaultValues.sample,
              targetRegionId: geneSetsId,
              sampleName: baseName
            },
            patient: {
              ...defaultValues.patient,
              fqFiles: otherFile
            }
          }
        }
      }
    })

    console.log(arr)

    setSamples(arr)
  }

  const handleLocalFiles = (
    files: { name: string; fullPath: string; size: number }[]
  ) => {
    setOssFiles(files)
    const fileData = files.map((items) => {
      console.log(items)

      const temp =
        items.name.includes('R1') || items.name.includes('R2') ? 'R' : '_'
      const nameArr = items.name.split('.')
      const suffix = nameArr[nameArr.length - 1]
      const tempR1 = items.name.split(`${temp}1.`)
      const tempR2 = items.name.split(`${temp}2.`)
      const fileType =
        items.name.includes('.vcf') ||
        items.name.includes('.vcf.gz') ||
        items.name.includes('.gvcf.gz')
          ? 'vcf'
          : items.name.includes('.gtc')
          ? 'gtc'
          : items.name.includes('.bam')
          ? 'bam'
          : items.name.includes('.fq.gz') || items.name.includes('.fastq.gz')
          ? 'fastq'
          : items.name.includes('.naf')
          ? 'naf'
          : 'vcf'

      console.log('123', tempR1, tempR2, fileType, items.name)

      if (tempR1.length === 2 && (fileType === 'fastq' || fileType === 'naf')) {
        // R1
        const str1 = items.name.split('_')[0]
        const str3 =
          items.name.split('_').length >= 4 ? items.name.split('_')[2] : null
        const first = items.name.includes('_L') ? `${str1}_${str3}` : tempR1[0]

        return {
          file: items,
          group: `${first}`
        }
      } else if (
        tempR2.length === 2 &&
        (fileType === 'fastq' || fileType === 'naf')
      ) {
        const str1 = items.name.split('_')[0]
        const str3 =
          items.name.split('_').length >= 4 ? items.name.split('_')[2] : null
        const first = items.name.includes('_L') ? `${str1}_${str3}` : tempR2[0]

        // R2
        return {
          file: items,
          group: `${first}`
        }
      } else {
        // vcf...
        return {
          file: items,
          group: `${items.name.split('_')[0]}${suffix}`
        }
      }
    })
    const groupedData: NObject = fileData.reduce((groups: any, item) => {
      const group = item.group

      if (!groups[group]) {
        groups[group] = []
      }

      groups[group].push(item)

      return groups
    }, {})

    console.log('123', fileData)

    const arr = Object.getOwnPropertyNames(groupedData).map((key) => {
      const item = groupedData[key] as {
        gropu: string
        file: { name: string; fullPath: string; size: number }
      }[]

      if (item && item.length === 1) {
        const file = item[0].file

        const fileName = file.name.replace(/\s+/g, '')
        let baseName = fileName.substring(0, fileName.lastIndexOf('.'))

        if (fileName.includes('.cnv')) {
          baseName = fileName.substring(0, fileName.lastIndexOf('.cnv'))
        }

        const user = JSON.parse(localStorage.getItem('user') || '{}')
        const geneSetsId = user.geneSetsId ?? null

        return {
          file: file as any,
          progress: 100,
          cnvProgress: 0,
          prefix: file.fullPath,
          cnvPrefix: '',
          fileSize: file.size / 1024 / 1024,
          cnvFileSize: 0,
          sampleValue: {
            ...defaultValues,
            sample: {
              ...defaultValues.sample,
              targetRegionId: geneSetsId,
              sampleName: baseName
            }
          }
        }
      } else {
        const [first, ...other] = item
        const file = {
          ...first.file,
          url: first.file.fullPath
        }
        const otherFile = other.map((item) => {
          return {
            fileName: item.file.name,
            file: {
              ...item.file,
              url: item.file.fullPath
            },
            process: 100,
            fileSize: item.file.size
          }
        })

        console.log(first, other, otherFile)

        const fileName = file.name.replace(/\s+/g, '')
        let baseName = fileName.substring(0, fileName.lastIndexOf('.'))

        if (fileName.includes('.cnv')) {
          baseName = fileName.substring(0, fileName.lastIndexOf('.cnv'))
        }

        const user = JSON.parse(localStorage.getItem('user') || '{}')
        const geneSetsId = user.geneSetsId ?? null

        return {
          file: file as any,
          progress: 100,
          cnvProgress: 0,
          prefix: file.fullPath,
          cnvPrefix: '',
          fileSize: file.size,
          cnvFileSize: 0,
          sampleValue: {
            ...defaultValues,
            sample: {
              ...defaultValues.sample,
              targetRegionId: geneSetsId,
              sampleName: baseName
            },
            patient: {
              ...defaultValues.patient,
              fqFiles: otherFile
            }
          }
        }
      }
    })

    console.log(arr)

    setSamples(arr)
  }

  const getUploadProgress2 = (
    value: any,
    type: string,
    index: number,
    Samples: any[]
  ) => {
    samples.map((sample: any, i) => {
      if (i === index) {
        if (type === 'fileSize') {
          sample.fileSize = value
        }

        if (type === 'progress') {
          sample.progress = value
        }

        if (type === 'prefix') {
          sample.prefix = value
        }
      }

      return sample
    })

    setKey(Math.random())
  }

  const getUploadProgress = (
    value: any,
    type: string,
    index: number,
    Samples: any[]
  ) => {
    const data = Samples.map((sample, i) => {
      if (i === index) {
        sample[type] = value

        if (type === 'fileSize') {
          sample.fileSize = value
        }

        if (type === 'progress') {
          sample.progress = value
        }

        if (type === 'prefix') {
          sample.prefix = value
        }
      }

      if (sample.hasCnv) {
        Samples.forEach((item) => {
          if (
            item.sampleValue.sample.sampleName ===
              sample.sampleValue.sample.sampleName &&
            item.isCnv
          ) {
            if (type === 'fileSize') {
              sample.cnvFileSize = item.fileSize
            }

            if (type === 'progress') {
              sample.cnvProgress = item.progress
            }

            if (type === 'prefix') {
              sample.cnvPrefix = item.prefix
            }
          }
        })
      }

      return sample
    })

    setSamples(data)
  }

  const handleSave = async (sample: any, cnvBatchId: any) => {
    const formField = sample.sampleValue
    const prefix = sample.prefix.split('?')[0]
    const cnvPrefix = sample.cnvPrefix
    const file = sample.file

    const user = JSON.parse(localStorage.getItem('user') || '{}')
    const email = user.email as string
    const first = email.split('@')[0]
    const second = email.split('@')[1].split('.com')[0]
    const useKey = `user_direct_uploads/${user.directUploadFolder}`
    const key = user.directUploadFolder
      ? useKey
      : `user_direct_uploads/${first}-${second}`

    console.log(formField, ossFiles)

    console.log(sample)

    const patientParams = {
      ...formField.patient,
      arrivalDate: formField.patient.acceptDate,
      receiveDate: formField.patient.acceptDate,
      dateOfBirth: formField.patient.birthday
        ? calculateBirthDate(Number(formField.patient.birthday))
        : null,
      birthday: formField.patient.birthday
        ? calculateBirthDate(Number(formField.patient.birthday))
        : null,
      physicianName: formField.patient.doctor,
      patientName: formField.patient.patientName,
      infirmaryName: formField.patient.infirmaryName,
      departmentName: formField.patient.departmentName,
      caseNumber: formField.patient.caseNumber,
      gender: formField.patient.gender,
      ethnicity: formField.patient.race,
      sampleType: formField.sample.sampleSource
    }

    delete patientParams.fqFiles

    delete patientParams.isFq
    const clinicalParams = {}
    const fastqFiles = _.cloneDeep(formField.patient.fqFiles) as {
      fileName: string
      process: number
      fileSize: number
      file?: any
    }[]
    const vcfFile = decodeURI(prefix.split('/')[prefix.split('/').length - 1])
    const fileType = vcfFile
    const sampleType =
      fileType.includes('.vcf') ||
      fileType.includes('.vcf.gz') ||
      fileType.includes('.gvcf.gz')
        ? 'vcf'
        : fileType.includes('.gtc')
        ? 'gtc'
        : fileType.includes('.bam')
        ? 'bam'
        : fileType.includes('.fq.gz') ||
          fileType.includes('.fastq.gz') ||
          fastqFiles.length
        ? 'fastq'
        : fileType.includes('.naf')
        ? 'naf'
        : 'vcf'

    console.log('sampleType', sampleType)

    const combinations = []

    if (sampleType === 'fastq' || sampleType === 'naf') {
      if (
        formField.patient &&
        formField.patient.fqFiles &&
        Array.isArray(formField.patient.fqFiles) &&
        formField.patient.fqFiles.length
      ) {
        let fqFiles = fastqFiles

        console.log(vcfFile, file)

        fqFiles.push({
          fileName: vcfFile ? vcfFile : file.name,
          process: 100,
          fileSize:
            sample.fileSize && !isOss
              ? Number(sample.fileSize.toFixed(2))
              : Number(file.size.toFixed(2)),
          file: file
        })

        console.log(fqFiles)

        fqFiles = unique(fqFiles, 'fileName')

        for (let i = 0; i < fqFiles.length - 1; i++) {
          for (let j = i + 1; j < fqFiles.length; j++) {
            const temp =
              fqFiles[i].fileName.includes('R1') ||
              fqFiles[i].fileName.includes('R2')
                ? 'R'
                : '_'
            let str = ''
            if (fqFiles[i].fileName.includes(`${temp}1`))
              str = fqFiles[i].fileName.split(`${temp}1`)[0]
            else if (fqFiles[i].fileName.includes(`${temp}2`))
              str = fqFiles[i].fileName.split(`${temp}2`)[0]

            console.log(
              `${str}`,
              fqFiles[j].fileName,
              fqFiles[j].fileName.includes(`${str}`)
            )

            if (
              fqFiles[j].fileName.includes(`${str}`) &&
              fqFiles[i].fileName.includes(`${temp}1`)
            )
              combinations.push({
                fastq_1: isOss
                  ? `${fqFiles[i].file.fullPath}`
                  : isLocal
                  ? `${fqFiles[i].file.fullPath}`
                  : fqFiles[i].fileName,
                fastq_2: isOss
                  ? `${fqFiles[j].file.fullPath}`
                  : isLocal
                  ? `${fqFiles[j].file.fullPath}`
                  : fqFiles[j].fileName
              })
            else if (
              fqFiles[j].fileName.includes(`${str}`) &&
              fqFiles[i].fileName.includes(`${temp}2`)
            )
              combinations.push({
                fastq_2: isOss
                  ? `${fqFiles[i].file.fullPath}`
                  : isLocal
                  ? `${fqFiles[i].file.fullPath}`
                  : fqFiles[i].fileName,
                fastq_1: isOss
                  ? `${fqFiles[j].file.fullPath}`
                  : isLocal
                  ? `${fqFiles[j].file.fullPath}`
                  : fqFiles[j].fileName
              })
          }
        }
      }
    }

    console.log(combinations)

    let termList = formField.clinical.termList

    if (formField.clinical.clinicalInformation && !termList.length) {
      termList = await translateValue(
        formField.clinical.clinicalInformation.split('，').join(',').split(',')
      )
    }

    const params = {
      name: formField.sample.sampleName,
      projectId: formField.sample.projectId,
      genomeBuild: formField.sample.genomeBuild,
      prefix: getCode(),
      folderPath: sampleType === 'fastq' || sampleType === 'naf' ? '' : prefix,
      gender: formField.patient.gender as 'female' | 'male',
      createdAt: new Date(),
      pcrFree: formField.patient.pcrFree,
      sentieonPipeline: formField.patient.sentieonPipeline,
      status: (formField.patient.status
        ? sampleType === 'fastq' || sampleType === 'naf'
          ? 'fastq_queuing'
          : sampleType === 'bam'
          ? 'bam_queuing'
          : 'vcf_queueing'
        : 'created') as 'vcf_queueing' | 'created',
      geneSetId: formField.sample.targetRegionId
        ? Number(formField.sample.targetRegionId)
        : null,
      genePanelId: formField.sample.genePanel,
      clinicalInformation: formField.clinical.clinicalInformation,
      terms: JSON.stringify(termList),
      isDelete: 0 as 0 | 1,
      removeDuplication: formField.patient.removeDuplication,
      vcfType: formField.sample.type as
        | 'wgs'
        | 'wes'
        | 'rna'
        | 'microarray'
        | 'gene_panel',
      patientParams,
      userId: user.id,
      fileSize:
        sampleType === 'fastq' || sampleType === 'naf'
          ? isOss || isLocal
            ? `${(
                fastqFiles.reduce(
                  (accumulator, currentValue) =>
                    accumulator + currentValue.fileSize,
                  0
                ) /
                1024 /
                1024
              ).toFixed(2)}`
            : `${fastqFiles
                .reduce(
                  (accumulator, currentValue) =>
                    accumulator + currentValue.fileSize,
                  0
                )
                .toFixed(2)}`
          : `${sample.fileSize.toFixed(2)}`,
      hasCnv: Boolean(sample.hasCnv || formField.patient.hasCnv),
      hasSv: Boolean(formField.patient.hasSv),
      svFile: decodeURI(formField.patient.svFile),
      cnvFile: cnvPrefix
        ? decodeURI(cnvPrefix.split('/')[cnvPrefix.split('/').length - 1])
        : formField.patient.cnvFile,
      vcfFile: isLocal || isOss ? prefix : vcfFile,
      sampleType,
      fastqFiles: combinations,
      hasFastTrack: sampleType === 'fastq' || sampleType === 'naf',
      cnvBatchTaskId: cnvBatchId,
      bamFile:
        sampleType === 'bam' ? (isLocal || isOss ? prefix : vcfFile) : null,
      sampleLocation: (isOss
        ? 'oss_user_direct_uploads'
        : isLocal
        ? 'local'
        : 'oss') as any
    }

    if (!params.name || !params.genomeBuild) return

    console.log('createparams', params, fastqFiles)

    const data = await handleSaveNewSam.mutateAsync(params)

    return {
      ...sample,
      id: data.id,
      terms: data.terms,
      clinicalInformation: data.clinicalInformation
    }
  }

  // 新建分析
  const handleSaveNewAna = useMutation({
    mutationFn: async (newProData: Analysis) => await saveAnalysis(newProData),
    onSuccess: () => {
      messageRef?.current?.handleClick(t('New successful'), 'success')
    },
    onError(error) {
      messageRef?.current?.handleClick(String(error), 'error')
    }
  })

  const handleSaveNewSam = useMutation({
    mutationFn: async (newSamoleData: Sample) =>
      await createSample(newSamoleData),
    onSuccess: (data) => {
      //post返回的结果
      messageRef?.current?.handleClick(t('New successful'), 'success')
    }
  })

  // const uploadStatus = React.useMemo(() => {
  //   const flag = samples.every(
  //     (items) =>
  //       items.prefix ||
  //       items.cnvPrefix ||
  //       (items.sampleValue.patient.fqFiles &&
  //         items.sampleValue.patient.fqFiles.length &&
  //         items.sampleValue.patient.fqFiles.every(
  //           (i: any) => i && i.process === 100
  //         ))
  //   )

  //   console.log(flag, samples)

  //   return flag
  // }, [samples])

  const save = async () => {
    const flag = samples.every(
      (items) =>
        (items.prefix &&
          items.sampleValue.patient.fqFiles &&
          items.sampleValue.patient.fqFiles.every(
            (i: any) => i && i.process === 100
          )) ||
        items.cnvPrefix
    )

    console.log(flag, samples)
    if (!flag)
      return messageRef?.current?.handleClick('请等待样本上传完成', 'warning')

    if (ossFiles.length) {
      const ossParams = ossFiles.map((item) => {
        return {
          fileName: item.name,
          isCreated: true,
          createdAt: new Date()
        }
      })

      await putUserCustomUploadsByList.mutateAsync({
        ossFiles: ossParams
      })

      setOssFiles([])
    }

    saveSamples()

    // if (samples) {
    //   if (props.setHasTermsOpen) {
    //     const hasNotTermsSamples = samples.filter(
    //       (sample) =>
    //         !(
    //           sample.sampleValue.clinical.termList &&
    //           sample.sampleValue.clinical.termList.length
    //         )
    //     )

    //     if (hasNotTermsSamples && hasNotTermsSamples.length) {
    //       console.log(hasNotTermsSamples)

    //       props.setHasTermsOpen && props.setHasTermsOpen(true)
    //     } else saveSamples()
    //   } else saveSamples()
    // }
  }

  // 创建一个防抖的按钮点击处理函数

  const handleClick = debounce(() => {
    setCount((prevCount) => prevCount + 1)

    console.log('Button clicked!')

    saveSamples()

    // 这里可以添加其他需要防抖处理的操作，比如 API 请求等
  }, 3000) // 设置防抖时间间隔为3000毫秒（3秒）

  const saveSamples = async () => {
    let cnvBatchId: any = null

    if (createRef.current) {
      const isCnvBatch = createRef.current.isCnvBatch

      if (isCnvBatch && createRef.current.createCnvBatchs) {
        const user = JSON.parse(localStorage.getItem('user') || '{}')
        const params: TaskWesCnvBatchDto = {
          status: TaskStatus.QUEUEING,
          userId: user.id,
          serverName: ServerName.LOCAL,
          createdAt: new Date(),
          updatedAt: new Date()
        }

        console.log(params)

        const data = await createRef.current.createCnvBatchs(params)

        if (data) {
          cnvBatchId = data.id
        }
      }
    }

    const sample = await Promise.all(
      _.cloneDeep(samples)
        .filter((item) => !item.isCnv)
        .map(async (sample) => {
          return await handleSave(sample, cnvBatchId)
        })
    )

    if (isFam) {
      const list = sample.filter((item) => item.id)

      if (list.length === 3) {
        const params = {
          name: sample[0].file.name,
          analysisType: 'family_analysis',
          project_id: null,
          description: '',
          status: 'queueing',
          sampleList: sample.map((item) => {
            let type = item.type

            if (type === 'child') {
              type = 'affected_child'
            }

            return {
              type: type,
              sample_id: item.id,
              clinicalInformation: item.clinicalInformation,
              terms: item.terms
            }
          })
        }

        handleSaveNewAna.mutateAsync(params)

        console.log(params)
      } else {
        messageRef?.current?.handleClick(t('家系新建失败'), 'errorerror')
      }
    } else if (isCarrier) {
      const list = sample.filter((item) => item.id)

      if (list.length === 2) {
        const params = {
          name: sample[0].file.name,
          analysisType: 'carrier',
          project_id: null,
          description: '',
          status: 'queueing',
          sampleList: sample.map((item) => {
            const type = item.type

            return {
              type: type,
              sample_id: item.id,
              clinicalInformation: item.clinicalInformation,
              terms: item.terms
            }
          })
        }

        handleSaveNewAna.mutateAsync(params)

        console.log(params)
      } else {
        messageRef?.current?.handleClick(t('家系新建失败'), 'errorerror')
      }
    }

    props.setLoading && props.setLoading(false)

    setTimeout(() => {
      props.fn && props.fn()
    }, 0)

    setSamples([])

    setIsOss(false)

    setIsLocal(false)

    setIsFam(false)

    setIsCarrier(false)
  }

  const translateValue = async (value: string[]) => {
    try {
      const obj = {} as any
      const sourceText = value.map((item, index) => {
        obj[index] = item
      })

      const sourceLanguage = 'zh',
        targetLanguage = 'en'

      const params: TranslateBulkDto = {
        sourceText: obj,
        sourceLanguage,
        targetLanguage
      }

      const data: { translated: string; wordCount: string }[] =
        await Translates.mutateAsync(params)

      if (params.sourceLanguage === 'zh') {
        const data2: { terms: string[] } =
          await GetTermByClinicalInfo.mutateAsync({
            clinicalInfo: data
              .map(
                (str) =>
                  str.translated.charAt(0).toUpperCase() +
                  str.translated.slice(1)
              )
              .join(',')
          })
        const sourceLanguage = 'en',
          targetLanguage = 'zh'

        const terms = await Promise.all(
          data2.terms.map(async (item) => {
            const params: TranslateDto = {
              sourceText: item,
              sourceLanguage,
              targetLanguage
            }

            const data = await Translate.mutateAsync(params)

            return {
              definition_cn: '',
              definition_en: '',
              hpoId: '',
              name_cn: data,
              name_en: item,
              altTitle: '',
              cnTitle: '',
              incTitle: '',
              mimNumber: 0,
              preTitle: data,
              prefix: '',
              title: `${item}(${data})`
            }
          })
        )

        console.log('terms', terms)

        return terms
      }
    } catch (error) {
      return []
    }
  }

  const Translates = useMutation({
    mutationFn: async (data: TranslateBulkDto) => await translates(data),
    onSuccess: (data: { translated: string; wordCount: string }[], params) => {
      //post返回的结果
    }
  })

  const Translate = useMutation({
    mutationFn: async (data: TranslateDto) => await translate(data),
    onSuccess: (data, variables, context) => {
      //post返回的结果
    }
  })

  const GetTermByClinicalInfo = useMutation({
    mutationFn: async (data: GetTermsByClinicalInfoDto) =>
      await getTermByClinicalInfo(data),
    onSuccess: async (data: { terms: string[] }) => {
      //post返回的结果
    }
  })

  const putUserCustomUploadsByList = useMutation({
    mutationFn: async (data: { ossFiles: UserDirectUploads[] }) =>
      await createUserDirectUploadsByList(data)
  })

  React.useEffect(() => {
    if (createRef.current) {
      const flag1 = samples.every(
        (items) =>
          (items.prefix &&
            items.sampleValue.patient.fqFiles &&
            items.sampleValue.patient.fqFiles.every(
              (i: any) => i && i.process === 100
            )) ||
          items.cnvPrefix
      )

      const flag2 = createRef.current.autoCreate

      if (flag1 && flag2) {
        saveSamples()

        console.log(123, samples, createRef.current?.autoCreate)
      }
    }
  }, [samples, createRef.current?.autoCreate])

  React.useImperativeHandle(ref, () => {
    return {
      save,
      confirm,
      saveSamples,
      samples
    }
  })

  return (
    <Box>
      {samples.length ? (
        <CreateSampleWizard
          getUploadProgress={getUploadProgress}
          getUploadProgress2={getUploadProgress2}
          samples={samples}
          setSamples={setSamples}
          ref={createRef}
          handleUpload={handleUpload}
        />
      ) : (
        <div>
          <Box>
            <FileUploaderMultiple
              disableList={true}
              accept=".vcf, .vcf.gz,.gvcf.gz, .gtc, .fq.gz, .fastq.gz,.naf,.bam,.fq"
              fn={handleUpload as any}
              key={'upload1'}
              ref={uploadFileRef}
            />
          </Box>

          <Stack>
            <Grid container spacing={2}>
              <Grid item columns={{ xs: 4, md: 6 }}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    ossRef.current &&
                      ossRef.current.handleOssClick &&
                      ossRef.current.handleOssClick()
                  }}
                >
                  {`${t('OSS web disk file creation')}`}
                </Button>
              </Grid>

              <Grid item columns={{ xs: 4, md: 6 }}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    localRef.current &&
                      localRef.current.handleLocalClick &&
                      localRef.current.handleLocalClick()
                  }}
                >
                  {t('Create a local server file')}
                </Button>
              </Grid>

              <Grid item columns={{ xs: 4, md: 6 }}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={(e) => {
                    if (
                      uploadFileRef.current &&
                      uploadFileRef.current.inputRef &&
                      uploadFileRef.current.inputRef.current &&
                      uploadFileRef.current.inputRef.current.click
                    ) {
                      setIsLocal(false)

                      setIsOss(false)

                      setIsFam(false)

                      setIsCarrier(false)

                      uploadFileRef.current.inputRef.current.click()
                    }
                  }}
                >
                  {t('Local file upload')}
                </Button>
              </Grid>

              <Grid item columns={{ xs: 4, md: 6 }}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={(e) => {
                    if (famRef.current && famRef.current.setOpen) {
                      setIsLocal(false)

                      setIsOss(false)

                      setIsFam(false)

                      setIsCarrier(false)

                      famRef.current.setOpen(true)
                    }
                  }}
                >
                  {t('Quick family merge upload')}
                </Button>
              </Grid>

              <Grid item columns={{ xs: 4, md: 6 }}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={(e) => {
                    if (famRef.current && famRef.current.setOpen) {
                      setIsLocal(false)

                      setIsOss(false)

                      setIsFam(false)

                      setIsCarrier(false)

                      carrierRef.current.setOpen(true)
                    }
                  }}
                >
                  {t('Carrier quickly merge upload')}
                </Button>
              </Grid>
            </Grid>

            <div
              style={{
                display: 'none'
              }}
            >
              <OssFileUploaderMultiple
                disableList={true}
                accept=".vcf, .vcf.gz,.gvcf.gz, .gtc, .fq.gz, .fastq.gz,.naf,.bam,.fq"
                fn={(files: any) => {
                  setIsLocal(false)

                  setIsOss(true)

                  setIsFam(false)

                  setIsCarrier(false)

                  handleLocalFiles(files)
                }}
                key={'upload2'}
                ref={ossRef}
              />
            </div>

            <div
              style={{
                display: 'none'
              }}
            >
              <LocalFileUploaderMultiple
                disableList={true}
                accept=".vcf, .vcf.gz,.gvcf.gz, .gtc, .fq.gz, .fastq.gz,.naf,.bam,.fq"
                fn={(files: any) => {
                  setIsLocal(true)

                  setIsOss(false)

                  setIsFam(false)

                  setIsCarrier(false)

                  handleLocalFiles(files)
                }}
                key={'upload2'}
                ref={localRef}
              />
            </div>

            <div
              style={{
                display: 'none'
              }}
            >
              <FamilyFileUploaderMultiple
                disableList={true}
                accept=".vcf, .vcf.gz,.gvcf.gz, .gtc, .fq.gz, .fastq.gz,.naf,.bam,.fq"
                fn={(files: any) => {
                  setIsLocal(false)

                  setIsOss(false)

                  setIsFam(true)

                  setIsCarrier(false)

                  handleUpload(files, false, true)
                }}
                key={'upload3'}
                ref={famRef}
              />
            </div>

            <div
              style={{
                display: 'none'
              }}
            >
              <CarrierFileUploaderMultiple
                disableList={true}
                accept=".vcf, .vcf.gz,.gvcf.gz, .gtc, .fq.gz, .fastq.gz,.naf,.bam,.fq"
                fn={(files: any) => {
                  setIsLocal(false)

                  setIsOss(false)

                  setIsFam(false)

                  setIsCarrier(true)

                  handleUpload(files, true, false)
                }}
                key={'upload4'}
                ref={carrierRef}
              />
            </div>
          </Stack>
        </div>

        // <Carousel
        //   arrows
        //   infinite={false}
        //   style={{
        //     width: 600
        //   }}
        // >

        //   <div>
        //     <OssFileUploaderMultiple
        //       disableList={true}
        //       accept=".vcf, .vcf.gz,.gvcf.gz, .gtc, .fq.gz, .fastq.gz,.naf,.bam,.fq"
        //       fn={handleOssFiles as any}
        //       key={'upload2'}
        //     />
        //   </div>
        // </Carousel>
      )}

      <PositionedSnackbar ref={messageRef} />
    </Box>
  )
})

export default CreateSample
