// ** React Imports
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography, { TypographyProps } from '@mui/material/Typography'

import Link from '@mui/material/Link'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Third Party Imports
import { useDropzone } from 'react-dropzone'
import {
  Autocomplete,
  Breadcrumbs,
  Card,
  CardActionArea,
  CardContent,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Stack,
  TextField,
  Tooltip
} from '@mui/material'
import Translations from '@/layouts/components/Translations'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import {
  assumeRole,
  createCatalog,
  createProject,
  createSampleByMountPath,
  createUserCustomUploads,
  deleteUserCustomUploads,
  editUserCustomUploads,
  fetchProjects,
  getUserCustomUploads,
  getUserDirectUploads,
  getUserFiles,
  Project,
  updateProject,
  UserCustomUploads,
  UserDirectUploads
} from '@/@core/services/app.service'
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid'
import { NObject } from '@/@core/components/filter-variants'
import { formatDate, isWindow, unique } from '@/@core/utils/utils'
import React from 'react'
import { CloseCircle, FileDocument, FolderOpen, Home } from 'mdi-material-ui'
import PositionedSnackbar from '@/@core/components/message'
import ProjectDialog from '@/views/samples/DialogProject'
import { CloudUploadTwoTone } from '@mui/icons-material'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiDialog-paper': {
    maxWidth: 1200,
    minHeight: 500
  }
}))

interface FileProp {
  name: string
  type: string
  size: number
}

interface FilmOptionType {
  inputValue?: string
  id?: number
  name: string
}
interface Props {
  fn?: (file: File[]) => void
  uploadFn?: (file: File[]) => void
  accept?: string
  disableList?: boolean
  sx?: any
}

// Styled component for the upload image inside the dropzone area
const Img = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(15.75)
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4)
  },
  [theme.breakpoints.down('sm')]: {
    width: 160
  }
}))

const TypographyEllipsis = styled(Typography)(() => ({
  width: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: '0.875rem'
}))

// Styled component for the heading inside the dropzone area
const HeadingTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4)
  }
}))

const CarrierFileUploaderMultiple = React.forwardRef((props: Props, ref) => {
  const { t } = useTranslation()

  // ** State
  const messageRef = React.useRef<any>()
  const [open, setOpen] = useState(false)
  const [mother, setMother] = React.useState<File[]>([])
  const [father, setFather] = React.useState<File[]>([])

  // ** Hooks
  const { getRootProps: getMotherRootProps, getInputProps: getMotherProps } =
    useDropzone({
      onDrop: (acceptedFiles: File[]) => {
        if (acceptedFiles.length) {
          setMother(acceptedFiles)
        }
      }
    })
  const { getRootProps: getFatherRootProps, getInputProps: getFatherProps } =
    useDropzone({
      onDrop: (acceptedFiles: File[]) => {
        if (acceptedFiles.length) {
          setFather(acceptedFiles)
        }
      }
    })

  React.useImperativeHandle(ref, () => {
    return { setOpen }
  })

  return (
    <Fragment>
      <BootstrapDialog
        onClose={() => {
          setOpen(false)
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle>
          <Translations text={t('Carrier file upload')}></Translations>
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={() => {
            setOpen(false)
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent dividers sx={{ width: 1200 }}>
          <Stack height={'100%'} spacing={2}>
            <Box>
              <Card>
                <CardActionArea>
                  <CardContent>
                    {father.length ? (
                      father.map((file) => {
                        return (
                          <Box>
                            <Typography fontSize={18} fontWeight={600}>
                              {t("man's SNV vcf or FastQ")}
                            </Typography>

                            <Stack direction={'row'} alignItems="center">
                              <Typography fontSize={18}>
                                {file.name.replace(/\s+/g, '')}
                              </Typography>

                              <IconButton
                                onClick={async () => {
                                  const data = father.filter(
                                    (item: File) => file.name !== item.name
                                  )

                                  setFather(data)
                                }}
                              >
                                <CloseCircle fontSize="small" />
                              </IconButton>
                            </Stack>
                          </Box>
                        )
                      })
                    ) : (
                      <div {...getFatherRootProps({ className: 'dropzone' })}>
                        <input
                          {...getFatherProps()}
                          accept={
                            '.vcf, .vcf.gz,.gvcf.gz, .gtc, .fq.gz, .fastq.gz,.naf,.bam,.fq'
                          }
                        />

                        <Stack
                          direction={'row'}
                          alignItems="center"
                          justifyContent={'start'}
                        >
                          <IconButton>
                            <CloudUploadTwoTone fontSize="large" color="info" />
                          </IconButton>

                          <Typography fontSize={18} fontWeight={600}>
                            {t("Upload the man's SNV vcf or FastQ")}
                          </Typography>
                        </Stack>
                      </div>
                    )}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Box>

            <Box>
              <Card>
                <CardActionArea>
                  <CardContent>
                    {mother.length ? (
                      mother.map((file) => {
                        return (
                          <Box>
                            <Typography fontSize={18} fontWeight={600}>
                              {t("woman's SNV vcf or FastQ")}
                            </Typography>

                            <Stack direction={'row'} alignItems="center">
                              <Typography fontSize={18}>
                                {file.name.replace(/\s+/g, '')}
                              </Typography>

                              <IconButton
                                onClick={async () => {
                                  const data = mother.filter(
                                    (item: File) => file.name !== item.name
                                  )

                                  setMother(data)
                                }}
                              >
                                <CloseCircle fontSize="small" />
                              </IconButton>
                            </Stack>
                          </Box>
                        )
                      })
                    ) : (
                      <div {...getMotherRootProps({ className: 'dropzone' })}>
                        <input
                          {...getMotherProps()}
                          accept={
                            '.vcf, .vcf.gz,.gvcf.gz, .gtc, .fq.gz, .fastq.gz,.naf,.bam,.fq'
                          }
                        />

                        <Stack
                          direction={'row'}
                          alignItems="center"
                          justifyContent={'start'}
                        >
                          <IconButton>
                            <CloudUploadTwoTone fontSize="large" color="info" />
                          </IconButton>

                          <Typography fontSize={18} fontWeight={600}>
                            {t("Upload the woman's SNV vcf or FastQ")}
                          </Typography>
                        </Stack>
                      </div>
                    )}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Box>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Stack
            width={'100%'}
            direction={'row'}
            spacing={4}
            margin={'10px 0'}
            alignItems="center"
            justifyContent={'center'}
          >
            <Button
              autoFocus
              variant="outlined"
              onClick={() => {
                setOpen(false)
              }}
            >
              {t('cancel')}
            </Button>

            <Button
              autoFocus
              variant="contained"
              onClick={() => {
                setOpen(false)
                const newMother = mother.map((file) => {
                  const newFileName = `mother_${file.name}`

                  const newFile = new File([file], newFileName, {
                    type: file.type,

                    lastModified: file.lastModified
                  })

                  return newFile
                })
                const newFather = father.map((file) => {
                  const newFileName = `father_${file.name}`

                  const newFile = new File([file], newFileName, {
                    type: file.type,

                    lastModified: file.lastModified
                  })

                  return newFile
                })

                props.fn && props.fn([...newMother, ...newFather])
              }}
            >
              {t('create')}
            </Button>
          </Stack>
        </DialogActions>
      </BootstrapDialog>

      <PositionedSnackbar ref={messageRef} />
    </Fragment>
  )
})

export default CarrierFileUploaderMultiple
